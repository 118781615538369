import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, lastValueFrom, tap } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { UserService } from './user.service';
import { LocalStorageService } from './local-storage.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from './error-handler.service';
import { BaseService } from './base/base.service';
import { MemberService } from './member.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  private loggedIn = new BehaviorSubject<boolean>(this.hasToken());
  isLoggedIn = this.loggedIn.asObservable();

  private env = environment;
  roles: Array<string> = [];

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService,
    private router: Router,
    private userService: UserService,
    private memberService: MemberService,
    private localStorageService: LocalStorageService) {
    super(http, errorHandlerService);
  }

  public hasToken(): boolean {
    return !!localStorage.getItem('token');
  }

  login(username: string, password: string, returnUrl = ''): Observable<LoginAuthModel> {
    let params = new HttpParams();
    params = params.append('showError', 'false');
    return this.post<LoginAuthModel>(`${this.env.authApiUrl}/auth/login`,
      {
        login: username,
        password: password,
        grantType:"password",
        modulesId: ["12472e7c-4abc-11e9-a4d1-0242ac110003","07498bb5-d7b4-4a4d-82f8-5eb9e2497478","e380f282-0015-40a9-aa06-e20b1f291d53"]
      },{params})
      .pipe(tap(auth => {
        if (auth && Object.keys(auth).length) {
          this.allowAccessWebsite(auth, returnUrl);
        }
      }));
  }
  

  loginMemberByBackoffice(userId: string): Observable<LoginAuthModel> {
    return this.http.get<LoginAuthModel>(`${this.env.publicApiAuthUrl}v1/auth/auth/login/${userId}`,
      )
      .pipe(tap(auth => {
        if (auth && Object.keys(auth).length) {
          this.allowAccessWebsite(auth, '');
        }
      }));
  }


  

  private async allowAccessWebsite(auth: any, returnUrl: string) {
    // Espera pegar os dados do usuário para redirecionar para as páginas protegidas, caso contrário não vai deixar aceder as páginas internas
    const userInfo = await lastValueFrom(this.memberService.getMemberPublicInfo(auth.login));
    if (userInfo) {

      this.localStorageService.setItem('token', auth.accessToken);
      this.loggedIn.next(true);
      this.router.navigate([returnUrl]);
    }
  }

  public getToken(): string {
    return this.localStorageService.getItem('token');
  }

  logout(returnUrl = '') {
    this.loggedIn.next(false);
    this.userService.removeCurrentUser();
    this.localStorageService.removeItem('token');
    this.router.navigate(['/login'], { queryParams: { returnUrl: returnUrl } });
  }

  isTokenExpired(token: string): boolean {
    const decodedToken = decodeJwt(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return decodedToken.exp <= currentTime;
  }

  recoverPassword(email: string): Observable<any> {
    return this.get(`${this.env.authApiUrl}/auth/user-request/recover-password-club?email=${email}`);
  }

  saveNewPassword(passwordObj:any, token: string): Observable<any> {
    return this.put(`${this.env.authApiUrl}/auth/user-request/password?requestToken=${token}`, passwordObj);
  }

  validateToken(token: string): Observable<any> {
    return this.put(`${this.env.authApiUrl}/auth/user-request/active-account?requestToken=${token}`,{});
  }

  changePasswordLoggedIn(newPassword: string, passwordConfirmation: string, authToken: string): Observable<void> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    });
  
    const body = {
      newPassword,
      passwordConfirmation
    };
  
    return this.http.put<void>(`${this.env.authApiUrl}/users/password`, body, { headers });
  }

  //criar get e set de roles
  setRoles(roles: Array<string>) {
    this.roles = roles;
  }

  getRoles() {
    return this.roles;
  }
}



function decodeJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );
  return JSON.parse(jsonPayload);
}
export interface LoginAuthModel {
  accessExpiresIn: number,
  accessToken: string,
  idSoccerTeam: string,
  login: string,
  name: string,
  refreshExpiresIn: number,
  refreshToken: string,
  roles: Array<string>,
  userId: string
}
